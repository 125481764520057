import React, { useState } from 'react'

export default function Subject(props: any){
    return (
        <a className={props.className} href={props.link}>
            <div>
                <em className={props.icon}></em>
                <div style={{"paddingTop":"16px"}}>{props.subject}</div>
            </div>
        </a>
    )
}
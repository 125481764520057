import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";

import Idiom from './component/Idiom';
import Countdown from './component/Countdown';
import Subjects from './component/Subjects';

export default function Home() {
  return (
    <div>
        <Link to='setting'><h1 style={{textAlign: 'center'}}>Ôn thi THPT{' '}<span> </span><span> </span><span> </span><span style={{color:'#0171ec'}} className="fa fa-cog"></span>
        </h1></Link>
        <Idiom/>
        <Countdown/>
        <Subjects/>
        <p></p>
        <p></p>
        <div>
            <div style = {{"color":"gray","fontSize":"10px","textAlign":"center"}}>Powered by <a href = "https://khangle.me">KL Studio</a></div>
            <div style={{"color":"gray","fontSize":"10px","textAlign":"center"}} >
                <a style={{"color":"gray","fontSize":"10px","textAlign":"center"}} href="mailto:contact@khangle.me" target="_blank" rel="noopener">Contact us</a>
            </div>
        </div>
        <p></p>
        <p></p>
    </div>
  );
}


import React, { useState } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './css/app.css';
import './css/segment.css';
import './css/font-awesome/css/all.css';

import Home from './Home';
import Setting from './component/Setting';
export default function App() {
  return (
    <div>
        <Router>
          <Routes>
            <Route>
              <Route path='/' element={<Home />} />
              <Route path="setting" element={<Setting />} />
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
        </Router>
    </div>

  );
}


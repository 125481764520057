import React, { useState } from 'react'

export default function Setting(){
    return (
        <div>
            <div className="head-navigation">
                <div className="left">
                    <a href="/">
                        <span style={{color:'#0171ec'}} className="fa fa-chevron-left"></span>
                    </a>
                </div>
                <div className="center">
                    <strong>Xoá lịch sử ôn tập và thi</strong></div>
                <div className="right">
                </div>
            </div>
                <p></p>
            <div className="page">
                <div>
                    Thao tác này sẽ khôi phục dữ liệu của phần mềm trở về thời điểm ban đầu, mọi câu trả lời mà bạn đã làm sẽ bị xóa.
                    Bạn có chắc chắn muốn tiếp tục?
                </div>
                <p style={{textAlign: 'center'}}>
                    <a onClick={reset} className="btn btn-danger">Có</a>
                    <a className="btn" style={{"marginLeft":"5px"}} href="/">Không</a>
                </p>
            </div>
        </div>
    )
}

function reset(){
    localStorage.clear()
    alert("Khôi phục dữ liệu thành công!");
    if (typeof window !== 'undefined') {
        window.location.href = "/";
   }
}



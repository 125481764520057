import React, { useState } from 'react'
import Subject from './Subject'

export default function Subjects(){
    return (
        <div className="page page-home">
            <div>
                <div style={{"display":"flex","justifyContent":"center"}}>
                    <Subject className = "item item-math" icon = "fa-home-page fa-square-root-variable" link="/math.html" subject="Toán học"></Subject>
                    <Subject className = "item item-physic" icon = "fa-home-page fa-wave-square" link="/chemistry.html" subject="Vật lý"></Subject>
                </div>
                <div style={{"display":"flex","justifyContent":"center"}}>
                    <Subject className = "item item-chemistry" icon = "fa-home-page fa-flask-vial" link="/chemistry.html" subject="Hóa học"></Subject>
                    <Subject className = "item item-english" icon = "fa-home-page fa-comments" link="/english.html" subject="Anh văn"></Subject>
                </div>
                <div style={{"display":"flex","justifyContent":"center","textAlign":"center"}}><strong>Cài đặt Ôn thi THPT lên điện thoại</strong>
                </div>
                <div style={{"display":"flex","justifyContent":"center"}}>
                    <a className="item" style={{"backgroundColor":"#4086cd"}} href="https://apps.apple.com/app/id1526944707">
                        <img style={{"borderRadius":"22px"}} src="images/assets/ios_qr.svg"/>
                    </a>
                    <a className="item" style={{"backgroundColor":"#4086cd"}} href="https://play.google.com/store/apps/details?id=me.khangle.thpt">
                        <img style={{"borderRadius":"22px"}} src="images/assets/android_qr.svg"/>
                    </a>
                </div>
                <div style={{"display":"flex","justifyContent":"center"}}>
                    <Subject className = "item item-bio" icon = "fa-home-page fa-dna" link="/bio.html" subject="Sinh học"></Subject>
                    <Subject className = "item item-geo" icon = "fa-home-page fa-globe" link="/geo.html" subject="Địa lý"></Subject>
                </div>
                <div style={{"display":"flex","justifyContent":"center"}}>
                    <Subject className = "item item-history" icon = "fa-home-page fa-clock-rotate-left" link="/history.html" subject="Lịch sử"></Subject>
                    <Subject className = "item item-gdcd" icon = "fa-home-page fa-scale-balanced" link="/gdcd.html" subject="GDCD"></Subject>
                </div>
                <div style={{"display":"flex","justifyContent":"center"}}>
                    <Subject className = "item item-nguvan" icon = "fa-home-page fa-book" link="/nguvan.html" subject="Ngữ văn"></Subject>
                    <Subject className = "item item-law" icon = "fa-home-page fa-car-burst" link="/law.html" subject="Luật giao thông"></Subject>
                </div>
                <div style={{"display":"flex","justifyContent":"center","color":"#0171ec","padding":"10px"}}>
                    <a className="item2" href="/sgk.html">
                        Sách giáo khoa <span style={{color:'#0171ec'}} className="fa fa-chevron-right"></span>
                    </a>
                    <a  className="item2" href="/stk.html">
                        Sách tham khảo <span style={{color:'#0171ec'}} className="fa fa-chevron-right"></span>
                    </a>
                </div>
            </div>
            
        </div>
    )
}
import React, { useState } from 'react'

export default function Countdown(){
    const [duration] = useState(changeDuration())
    return (
        <div className="controls-container" style={{margin: '0px 0 25px'}}>
            <div style={{display: 'grid',width: '100%',maxWidth: '500px', justifyContent: 'right',margin: 'auto',textAlign: 'right'}}>
                <span style={{width: '100%',height:'100%',textAlign: 'right',color: 'gray',fontStyle: 'italic'}}>còn {duration} ngày</span>
                <span style={{width: '100%',height:'100%',textAlign: 'right',color: 'darkgray',fontStyle: 'italic',fontSize: '12px'}}>dự kiến 6/7/2023</span>
            </div>
        </div>
    )


}
let changeDuration = function(){
    return Math.ceil(Math.abs(new Date('7/6/2023').valueOf() - new Date().valueOf()) / 86400000)
}
import React, { useState } from 'react';

export default function Idiom(){
    const idioms = [
      "Ngọc kia chẳng giũa chẳng mài. Cũng thành vô dụng cũng hoài ngọc đi"
    , "Luyện mãi thành tài, miệt mài tất giỏi"
    , "Dốt đến đâu học lâu cũng biết"
    , "Học hành vất vả kết quả ngọt bùi"
    , "Tài chí bằng trời, chẳng học cũng là phải khổ"
    , "Hay học thì sang, hay làm thì có"
    , "Hãy đặt tương lai vào bàn tay đáng tin cậy - bàn tay của chính bạn"
    , "Tương lai được mua bằng hiện tại"
    , "Học từ ngày hôm qua, sống ngày hôm nay, hi vọng cho ngày mai. Điều quan trọng nhất là không ngừng đặt câu hỏi"
    , "Không có áp lực, không có kim cương"
    , "Nghị lực và bền bỉ có thể chinh phục mọi thứ"
    , "Hãy học khi người khác ngủ; lao động khi người khác lười nhác; chuẩn bị khi người khác chơi bời; và có giấc mơ khi người khác chỉ ao ước"
    , "Bạn có thể học được từ thầy giáo, nhưng bạn sẽ phải tự học rất nhiều với bản thân, ngồi đơn độc trong một căn phòng"
    , "Học... học để là chính mình, và học để từ bỏ với vẻ thanh cao những gì không phải là mình"
    , "Học từ ngày hôm qua, sống ngày hôm nay, hi vọng cho ngày mai. Điều quan trọng nhất là không ngừng đặt câu hỏi"
    , "Đối với người trưởng thành, sự lựa chọn đầu tiên là luôn xem xét và tự quản lý việc học tập"
    , "Học tập là vấn đề thái độ chứ không phải là năng khiếu"
      , "Điều tuyệt vời nhất của việc học hành là không ai có thể lấy nó đi khỏi bạn"
      , "Học không phải về việc thế giới đang làm gì, mà là những gì bạn có thể làm cho nó"
      , "Một khi bạn ngừng học tập thì bạn sẽ chết"
      , "Đừng xấu hổ khi bạn không biết, ta chỉ xấu hổ khi không học"
      , "Nhà trường cho ta chiếc chìa khóa của tri thức, học tập trong cuộc sống chính là công việc cả đời"
      , "Bạn có thể học được từ thầy giáo, nhưng bạn sẽ phải tự học rất nhiều với bản thân, ngồi đơn độc trong một căn phòng"
      , "Những gì chúng ta muốn làm một cách thoải mái, chúng ta phải học làm một cách siêng năng trước"
      , "Hãy học khi người khác ngủ; lao động khi người khác lười nhác; chuẩn bị khi người khác chơi bời; và có giấc mơ khi người khác chỉ ao ước"
      , "Biết cách học là đủ chứng tỏ bạn thông thái"
      , "Học mà không nghĩ là phí công"
      , "Không có kho báu nào quý bằng học thức. Hãy tích lũy nó bất cứ lúc nào có thể"
      , "Tri thức là sức mạnh"
      , "Cần phải lựa chọn một cách nghiêm ngặt xem nên học gì và không nên học gì"
      , "Đọc sách không bằng suy ngẫm, Học trường không hơn được trường đời"
      , "Nghị lực và bền bỉ có thể giúp bạn chinh phục mọi thứ"
      , "Cuộc đời sẽ tươi đẹp hơn rất nhiều nếu ta có những tình bạn đúng nghĩa, luôn hết mình vì người khác."
      , "Một chữ cũng là thầy, nửa chữ cũng là thầy"
      , "Vấp ngã không phải là thất bại, chỉ là dừng lại cho đỡ mỏi chân thôi"
      , "Giáo dục là làm cho con người tìm thấy chính mình"
      , "Ước mơ không có ngày hết hạn, hãy hít thở sâu và cố gắng thêm lần nữa"
      , "Cuộc sống rất ngắn. Đừng lẵng phí nó bởi những nỗi buồn. Hãy là chính mình, luôn vui vẻ, tự do, và trở thành bất cứ gì bạn muốn"
      , "Phía sau tôi không có lấy một người, sao tôi dám ngã xuống"
      , "Trường học có thể hô biến những người thắng và người bại, nhưng cuộc sống thì không"
      , "Muốn xây dựng đất nước, trước hết phải phát triển giáo dục. Muốn trị nước, phải trọng dụng người tài"
      , "Thời gian còn lại rất ngắn. Hãy cố gắng hết sức mình, để không phải hối tiếc"
      , "Con đường do bản thân lựa chọn, dù có quỳ cũng phải đi cho hết"
      , "Thái độ bây giờ của bạn sẽ quyết định mười năm sau bạn là một nhân vật tầm cỡ hay chỉ là một kẻ thất bại"
      , "Nếu như không có vận may, thì hãy thử dùng dũng khí"
      , "Để có thể thành công, bạn cần phải tin rằng mình có thể"
      , "Cách tốt nhất để làm mọi thứ là ngừng nói và làm ngay bây giờ"
      , "Bạn chỉ thất bại khi bạn ngừng cố gắng"
    ]
    const [idiom, setIdiom] = useState(changeIdiom());
  
    return (
      <div className="controls-container" style={{margin: '25px 0 25px'}} onClick={() => setIdiom(changeIdiom())}>
          <div className="controls" style={{width: '100%',lineHeight: '28px', maxWidth: '500px'}}>
              <span style={{padding: '10px',width: '100%',height:'100%',textAlign: 'center',color: '#0171ec'}}>{idiom}</span>
          </div>
      </div>
    )
  
    function changeIdiom()
    {
      let index = Math.floor(Math.random() * idioms.length)
      return idioms[index]
    }
  
  }